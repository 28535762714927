@font-face {
  font-family: 'Akrobat-Black';
  src: url("./assets/font/akrobat/Akrobat-Black.otf") format('truetype');
}

@font-face {
  font-family: 'Akrobat-LightExtra';
  src: url("./assets/font/akrobat/Akrobat-ExtraLight.otf") format('truetype');
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.roles {
  width: 100%; min-height: 10vh;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .roles-wrap {
    width: 100%; min-height: 70vh;
    @include flexCenter();
    flex-direction: column;

    .roles-wrap__row {
      width: 100%;
      @include flexCenter();

      .roles-wrap__row__element {
        width: 50%; min-height: 150px; max-height: 200px;
        @include flexCenter();
        gap: 20px;
        border-top: 1px solid #f6f6f6;
        padding: 30px 30px;

        .afterLine {
          width: 4px; height: 150px;
          background-color: #f6f6f6;
          margin: auto 0;
        }

        .element__image {
          width: 50%; height: 100%;
          @include flexCenter();

          .image {
            width: 100%; height: 100%;
          }
        }

        .element__info {
          
          .info__title {
            font-family: "Akrobat-Black", sans-serif;
            font-weight: 900;
            font-size: 16px;
            color: #000;
            text-decoration: none;
            margin-top: 10px;
          }

          .info__good__weight,
          .info__paragraph {
            font-family: "Akrobat-LightExtra", sans-serif;
            margin-top: 10px;
          }

          .info__good__weight {
            font-weight: 200;
          }

          .info__buy-row {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            
            .row__cart {
              background-color: #E11F1D;
              border: none;
              padding: 8px 16px;
              border-radius: 49px;
              @include flexCenter();
              gap: 10px;
              color: #fff;
              font-size: 16px;
              text-transform: uppercase;
              cursor: pointer;
            }

            .row__select-number {
              @include flexCenter();
              gap: 10px;

              .selection__button {
                width: 15px; height: 15px;
                border-radius: 100%;
                border: none;
                @include flexCenter();
                align-items: flex-end;
                color: #E11F1D;
                cursor: pointer;
              }

              .selection__number {
                font-weight: 800;
                font-size: 16px;
                text-align: center;
                text-transform: uppercase;
                font-family: 'Akrobat-Black', sans-serif;
                color: #1D1D1B;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 994px) {
    .roles-wrap {
      .roles-wrap__row {
        flex-direction: column;

        .roles-wrap__row__element {
          width: 100%;
          justify-content: flex-start;
          flex-direction: row;

          .afterLine {
            display: none;
          }

          .element__image {
            width: 20%;
          }
        }
      }
    }
  }

  @media (max-width: 561px) {
    .roles-wrap {
      .roles-wrap__row {
        .roles-wrap__row__element {
          .element__image {
            width: 40%;
          }
        }
      }
    }
  }

  @media (max-width: 439px) {
    .roles-wrap {
      .roles-wrap__row {
        .roles-wrap__row__element {
          .element__image {
            width: 60%;
          }
        }
      }
    }
  }

  @media (max-width: 352px) {
    .roles-wrap {
      .roles-wrap__row {
        .roles-wrap__row__element {
          .element__image {
            width: 80%;
          }
        }
      }
    }
  }

  @media (max-width: 281px) {
    .roles-wrap {
      .roles-wrap__row {
        .roles-wrap__row__element {
          .info__buy-row {
            .row__cart {
              font-size: 12px!important;
              gap: 1px!important;
              padding: 5px 5px!important;
            }
          }
        }
      }
    }
  }
}