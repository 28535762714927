@font-face {
  font-family: "Akrobat-Bold";
  src: url('./assets/font/akrobat/Akrobat-Bold.otf');
}

.home {
  width: 100%; min-height: 80vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .home__header {
    width: 100%; height: 28vh;
    position: absolute;
    z-index: 999;
    top: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .home__title {
      font-family: "Akrobat-Bold";
      text-transform: uppercase;
      color: rgb(255, 255, 255);
      text-align: center;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 64px;
    }

    .home__description {
      font-family: "Akrobat-Bold";
      color: rgb(255, 255, 255);
      text-align: center;
      letter-spacing: 1px;
      font-weight: 100;
      font-size: 32px;
      opacity: .8;
    }

    .home__btn {
      background-color: rgb(255, 255, 255);
      font-family: "Akrobat-Bold";
      text-transform: uppercase;
      color: rgb(225, 31, 29);
      font-weight: 900;
      font-size: 32px;
      padding: 12px 36px;
      border-radius: 50px;
      cursor: pointer;
      border: none;
      transition: all .2s ease-in-out;

      &:hover {
        background-color: rgba(30, 29, 27, 1);
        color: #fff;
      }
    }
  }

  // Media queries
  @media (max-width: 808px) {
    .home__header {
      top: 20%;
      height: 17vh;

      .home__title {
        font-size: 32px;
      }

      .home__description {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 433px) {
    .home__header {
      height: 20vh;

      .home__title {
        font-size: 30px;
      }

      .home__description {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 367px) {
    .home__header {
      height: 17vh;

      .home__title {
        font-size: 25px;
      }

      .home__description {
        font-size: 12px;
      }

      .home__btn {
        font-size: 25px;
        padding: 12px 18px;
      }
    }
  }

  @media (max-width: 281px) {
    .home__header {
      height: 25vh;

      .home__title {
        font-size: 20px;
      }

      .home__btn {
        font-size: 20px;
        padding: 10px 15px;
      }
    }
  }


  @media (max-height: 658px) {
    .home__header {
      height: 40vh;
      top: 20%;
    }
  }

  @media (max-height: 453px) {
    .home__header {
      height: 50vh;
      top: 33%;
    }
  }
}