@font-face {
  font-family: 'Akrobat-Bold';
  src: url("./assets/font/akrobat/Akrobat-Bold.otf") format('truetype');
}

.header {
  width: 100%; max-height: 250px;
  background-color: #1D1D1B;
  padding-top: 25px;
  z-index: 2;

  .afterWrap {
    height: 1px;
    background-color: #333;
    margin: 20px auto!important;
  }

  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-part,
    .right-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .left-part {
      width: 31%;

      .wrap__site-branding {
        cursor: pointer;
      }
    }

    .right-part {
      width: 50%;
    }

    .wrap__nav {

      .primary-navigation {
        .navigation__menu {
          list-style-type: none;
          display: flex;

          .about-item {
            width: 80px; 
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .menu__item {
            a {
              text-decoration: none;
              font-family: "Akrobat-Bold";
              color: #fff;
              font-size: 16px;
              font-weight: 800;
              display: flex;
              flex-direction: column-reverse;

              .beforeLine {
                width: 100%; height: 2px;
                background-color: #ff3c00;
                margin-top: 5px;
                visibility: hidden;
              }
            }

            .afterLine {
              width: 8px; height: 1px;
              opacity: .5;
              border: 1px solid #fff;
              transform: rotate(90deg);
            }

            .contacts {
              margin-left: 30px;

              &:hover {
                .beforeLine {
                  visibility: visible;
                }
              }
            }

            .about-us {
              &:hover {
                .beforeLine {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }

    .header-phone-number {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .header-city-name {
        .comment {
          font-family: "Akrobat-Bold";
          font-size: 16px;
          color: #f6f6f6b0;

          .cityName {
            font-weight: 800;
            color: #fcfcfc;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }

      .city-phone-number {
        font-weight: 800;
        font-size: 32px;
        text-transform: uppercase;
        color: #fff;
        font-family: "Akrobat-Bold";
      }
    }

    .header__cart-button {
      background-color: #fff;
      color: #E11F1D;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 800;
      font-size: 16px;
      text-transform: uppercase;
      padding: 20px 50px;
      border-radius: 50px;
      text-decoration: none;
      cursor: pointer;
      font-family: "Akrobat-Bold";

      .cart-info {
        margin-left: 10px;
      }
    }

    .wrap__profile {
      width: 56px; height: 56px;
      background-color: #fff;
      border-radius: 100%;

      a {
        width: 100%; height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .menu-toggle {
      display: none;
    }
  }

  .secondary-wrap {
    .listOfLinks {
      width: 100%; min-height: 70px;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .link-item {

        .item-link {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
          text-shadow: none;
          cursor: pointer;
          padding-bottom: 5px;
          background-color: transparent;
          border: none;

          &:hover{
            border-bottom: 1px solid #ff3c00;
          }

          .link-img {
            width: 24px; height: 24px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  // Media quries
  @media (max-width: 1199px) {
    padding-top: 0;

    .header-wrap {
      width: 900px;

      .right-part {
        width: 35%;
      }
  
      .wrap__nav {
        display: none;
      }
  
      .header__cart-button {
        display: none!important;
      }
  
      .wrap__profile {
        display: none;
      }
  
      .menu-toggle {
        display: flex!important;
        background-color: transparent;
        border: none;
        cursor: pointer;
  
        .menu-icon {
          width: 29px; height: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
  
          .icon-line {
            width: 100%; height: 4px;
            background-color: #fff;
          }
        }
      }
    }
    
    .secondary-wrap {
      display: none;
    }
  }

  @media (max-width: 929px) {
    .header-wrap {
      width: 700px;
      padding: 10px;
    }
  }

  @media (max-width: 733px) {
    .header-wrap {
      width: 500px;

      .right-part {
        width: 55%;
      }
    }
  }

  @media (max-width: 517px) {
    .header-wrap {
      width: 400px;

      .right-part {
        width: 70%;
      }
    }
  }

  @media (max-width: 419px) {
    .header-wrap {
      width: 250px;

      .right-part {
        width: 20%;
        justify-content: flex-end;

        .header-phone-number {
          display: none;
        }
      }
    }
  }
}