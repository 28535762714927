@font-face {
  font-family: "Akrobat-Bold";
  src: url('./assets/font/akrobat/Akrobat-Bold.otf');
}

.footer {
  width: 100%;
  padding: 45px 0;
  background-color: #1d1d1b;

  .row {
    height: 169px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .footer__left {
      min-width: 55%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .nav__links {
        width: 80%; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .links__link-wrapper {
          .wrapper__link {
            text-decoration: none;
            font-size: 16px;
            color: #fff;
            font-family: "Akrobat-Bold";
            display: flex;
            flex-direction: column-reverse;

            .last {
              &::after {
                content: '';
              }
            }

            &:hover {
              .before {
                visibility: visible;
              }
            }

            &::after {
              content: '';
              position: relative;
              top: 12px; left: 70px;
              width: 8px; height: 0;
              opacity: .5;
              border: 1px solid #fff;
              transform: rotate(90deg);
            }

            .before {
              background-color: #e11f1d;
              width: 100%; height: 2px;
              margin-top: 6px;
              visibility: hidden;
            }
          }
        }
      }
    }

    .footer__right {
      min-width: 30%; height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;

      .footer__right-top {
        .footer__social-media {
          svg {
            margin-left: 24px;
            cursor: pointer;
  
            &:hover {
              path {
                fill: #e11f1d;
              }
            }
          }
        }
      }

      .footer__right-bottom {
        .footer__info-paragraph {
          font-weight: 400;
          font-size: 16px;
          color: #fff;
          opacity: .8;
          text-align: right;
        }

        .footer__info-number {
          color: #fff;
          font-weight: 800;
          font-size: 32px;
          text-align: right;
        }
      }
    }
  }

  // Media queries
  @media (max-width: 992px) {
    .container {
      width: 70%;
    }

    .row {
      .footer__left {
        min-width: 15%;

        .nav__links {
          display: none;
        }
      }

      .footer__right {
        min-width: 90%; height: 90%;
        justify-content: space-around;
      }
    }
  }

  @media (max-width: 281px) {
    .footer__left {
      min-width: none;
      width: 100%;
      justify-content: center!important;
    }

    .footer__right {
      display: none!important;
    }
  }
}