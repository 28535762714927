@font-face {
  font-family: "Akrobat-Bold";
  src: url('./assets/font/akrobat/Akrobat-Bold.otf');
}

* {
  padding: 0; margin: 0;
  box-sizing: border-box;
  font-family: "Akrobat-Bold", sans-serif;
}

.App {
  width: 100%; min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.App {
  width: 100%; height: 100%;
}

.App .container {
  max-width: 1110px;
  margin: 0 auto;
}

.App .mainPage {
  width: 100%; height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}