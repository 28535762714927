@font-face {
  font-family: "Akrobat-Bold";
  src: url('../assets/font/akrobat/Akrobat-ExtraBold.otf');
}

@font-face {
  font-family: "Akrobat-Regular";
  src: url('../assets/font/akrobat/Akrobat-Regular.otf');
}

@font-face {
  font-family: "Akrobat-ExtraLight";
  src: url('../assets/font/akrobat/Akrobat-ExtraLight.otf');
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flexAlignStart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.details {
  width: 100%; min-height: 100vh;
  @include flexCenter();
  display: none;
  margin-top: 100px;

  &__form {
    width: 100%;
    @include flexSpaceBetween();
    align-items: flex-start;

    .left-form-content {
      width: 50%;
      @include flexAlignStart();
      flex-direction: column;

      .form__title {
        font-family: "Akrobat-Bold";
        font-size: 32px;
        color: #1d1d1b;
      }

      .form__inputs {
        width: 100%;
        @include flexAlignStart();
        gap: 30px; margin-top: 40px;
        flex-direction: column;

        fieldset {
          width: 100%;
          position: relative;
          border: none;

          input {
            width: 100%;
          }

          label {
            top: 20px; left: 20px;
            position: absolute;
            font-family: "Akrobat-Regular";
            color: rgb(122, 121, 122);

            .redStar {
              color: #e11f1d;
            }
          }
        }

        .form__input-row {
          width: 100%;
          @include flexSpaceBetween();
        }

        .form__input {
          width: 100%;
          font-family: "Akrobat-Regular";
          background-color: #f6f6f6;
          border-radius: 4px 4px 0px 0px;
          padding: 20px;
          font-size: 16px;
          border: none;

          &:focus {
            outline: none;
          }
        }

        textarea {
          width: 100%; max-height: 80px;
          font-family: "Akrobat-Regular";
          background-color: #f6f6f6;
          border-radius: 4px 4px 0px 0px;
          padding: 20px;
          font-size: 16px;
          border: none;
          resize: none;
        }

        .first-row {
          fieldset {
            width: 45%;
          }
        }

        .second-row {
          fieldset {
            width: 30%;
          }
        }
      }
    }

    .right-form-content {
      width: 40%; min-height: 100%;
      @include flexAlignStart();

      .content-wrap {
        width: 100%;
        @include flexSpaceBetween();
        align-items: flex-start;
        gap: 30px;
        flex-direction: column;
        padding: 30px;
        background-color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;

        &__title {
          font-size: 32px;
          font-family: "Akrobat-Bold";
        }

        &__list {
          width: 100%; min-height: 80px;
          overflow: auto;

          &__item {
            width: 100%; height: 80px;
            border-top: 2px solid #e8e8e8;
            border-bottom: 2px solid #e8e8e8;
            @include flexSpaceBetween();
            padding: 10px 0;
            margin-top: 5px;

            &__left-part {
              &__name {
                color: #1d1d1b;
                font-size: 16px;
                font-family: "Akrobat-ExtraLight";
              }

              &__weight {
                color: #1d1d1b;
                font-size: 12px;
                font-family: "Akrobat-ExtraLight";
              }
            }
          }
        }

        &__price {
          width: 100%;
          text-align: right;
          font-size: 16px;
          font-family: "Akrobat-Regular";

          span {
            color: #e11f1d;
            font-size: 32px;
            font-family: "Akrobat-Bold";
          }
        }

        .select {
          width: 100%;
          position: relative;
          overflow: hidden;

          &::after {
            content: "▼";
            padding: 12px 8px;
            position: absolute;
            right: 20px; top: 10px;
            z-index: 1;
            text-align: center;
            width: 10px; height: 10px;
            pointer-events: none;
            color: #e11f1d;
          }

          &__pay-method {
            width: 100%;
            font-family: "Akrobat-Regular";
            background-color: #f6f6f6;
            border-radius: 4px 4px 0px 0px;
            padding: 20px;
            font-size: 16px;
            border: none;
            position: relative;
            appearance: none;
  
            &:focus {
              outline: none;
            }
          }
        }

        &__confirm-order {
          min-width: 100%;
          border: none;
          background-color: #e11f1d;
          color: #fff;
          font-family: "Akrobat-Bold";
          text-transform: uppercase;
          border-radius: 49px;
          padding: 20px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1110px) {
    &__form {
      gap: 40px;
      align-items: center;
      flex-direction: column;

      .right-form-content {
        width: 90%;
      }

      .left-form-content {
        width: 90%;
      }
    }
  }

  // @media (max-width: 751px) {
  //   &__form {
  //     .right-form-content {
  //       .content-wrap {
  //         &__title {
  //           font-size: 20px;
  //         }

  //         &__price {
  //           span {
  //             font-size: 20px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}